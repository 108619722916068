import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import sk from './sk.json'
// import en from './en.json'
// import uk from './uk.json'
// import de from './de.json'
import { MAIN_LANGUAGE, ALL_LANGS, RAMI_ONLINE_LANGUAGE_COOKIES } from '../constants';
import { getCookie, setCookie } from '../functions/cookie';

const resources = {
    SK: {
        translation: sk
    },
    // EN: {
    //     translation: en
    // },
    // UK: {
    //     translation: uk
    // },
    // DE: {
    //     translation: de
    // },
    sk: {
        translation: sk
    },
    // en: {
    //     translation: en
    // },
    // uk: {
    //     translation: uk
    // },
    // de: {
    //     translation: de
    // },
};
const urlParams = new URLSearchParams(window.location.search);
const lngParam = urlParams.get('lang');
const actualLang = ALL_LANGS.find((l) => lngParam === l);
if (!getCookie(RAMI_ONLINE_LANGUAGE_COOKIES) && lngParam) {
    setCookie(RAMI_ONLINE_LANGUAGE_COOKIES, actualLang || MAIN_LANGUAGE, 365);
}

i18n

    .use(initReactI18next)
    .init({
        resources,
        // lng: actualLang || getCookie(RAMI_ONLINE_LANGUAGE_COOKIES) || MAIN_LANGUAGE,
        lng: actualLang || MAIN_LANGUAGE,
        fallbackLng: ALL_LANGS,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;