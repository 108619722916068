import { combineReducers, createStore, applyMiddleware, compose  } from 'redux';
import HomeReducer from './HomeReducer';
import BlogReducer from './BlogReducer';
import NewsReducer from './NewsReducer';
import PostReducer from './PostReducer';
import NewsPostReducer from './NewsPostReducer';
import CustomMaterialsPageReducer from './CustomMaterialsPageReducer';
import CertificationsReducer from './CertificationsReducer';
import CasesReducer from './CasesReducer';
import MaterialsReducer from './MaterialsReducer';
import ContactReducer from './ContactReducer';
import CaseReducer from './CaseReducer';
import CustomPageReducer from './CustomPageReducer';
import ContainerReducer from './ContainerReducer';
import CareerReducer from './CareerReducer';
import CareerOfferReducer from './CareerOfferReducer';
import SearchReducer from './SearchReducer';
import EquipmentReducer from './EquipmentReducer';
import ServicesReducer from './ServicesReducer';
import CityReducer from './CityReducer';
import RentalRulesReducer from './RentalRulesReducer';
import RentalReducer from './RentalReducer';
import MainReducer from './MainReducer';
import thunk from 'redux-thunk';

export default createStore(
    combineReducers({
        CustomMaterialsPageReducer,
        HomeReducer,
        BlogReducer,
        PostReducer,
        NewsReducer,
        CertificationsReducer,
        NewsPostReducer,
        CasesReducer,
        MaterialsReducer,
        ContactReducer,
        CaseReducer,
        CustomPageReducer,
        ContainerReducer,
        CareerReducer,
        CareerOfferReducer,
        SearchReducer,
        EquipmentReducer,
        ServicesReducer,
        CityReducer,
        RentalRulesReducer,
        RentalReducer,
        MainReducer,
    }),
    compose(
        applyMiddleware(thunk),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);
