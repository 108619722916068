import React, { useCallback } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { classNames } from '../../../functions/classNames';
import RouteLinkComponent from '../../../components/RouteLinkComponent';
// import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next';
import { gaEventPush } from '../../../functions/ga';

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        fontSize: 14,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        width: '100%',
        height: 'fit-content',
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        },
    },
    titleBox: {
        margin: 5,
        position: 'relative',
        height: 48,
        minHeight: 48,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            height: 42,
            minHeight: 42,
        },
    },
    img: {
        filter: 'grayscale(1) opacity(0.4)',
        width: 'auto',
        objectFit: 'contain',
        height: 40,
        minHeight: 40,
        transition: 'all 0.2s ease-in',

    },
    icon: {
        width: 'auto',
        height: 40,
        minHeight: 40,
        transition: 'all 0.2s ease-in',
        '& g': {
            opacity: 1,
        },
        '& path': {
            transition: 'fill 0.2s ease-in',
            fill: theme.palette.gray.main,
            opacity: 1,
        },

    },
    a: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    container: {
        padding: 10,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        '&:hover': {
            boxShadow: theme.shadows[2],
            overflow: 'hidden',
            '& path': {
                fill: theme.palette.primary.main,
            },
            '& img': {
                filter: 'none',
            },
            '& p': {
                fontSize: 13.8,
                fontWeight: 550,
            }
        },
        transition: 'all 0.2s ease-in',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 'none',
        },
        '@media (max-width: 400px)': {
            padding: 5,
        },
    },
    mainCont: {
        margin: '5px',
        position: 'relative',
        height: 'calc(100% - 5px)',
        '&::after': {
            content: "''",
            display: 'block',
            paddingBottom: '100%',
        },
        [theme.breakpoints.down('md')]: {
            margin: '2.5px',
        },
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 5px)',
            margin: '2.5px',
            height: 'inherit',
        },
    },

}))

const ServiceComponent = ({ to, src, title, activeSrc, slug, Icon, titleVariant = 'h3' }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const handleGA = useCallback(() => {
        if (!to) 
            gaEventPush('Social', `${t('ga.navigation_category')} ${title}`)
    }, [t, title, to])
    return (
        <div>
            <Box className={classes.mainCont}>
                <RouteLinkComponent to={to ? slug : false} href={to ? false : slug} linkCustomClass={classes.a} customClass={classes.a} hiddenBlank>
                    <Paper className={classNames([classes.container, 'categories'])} onClick={handleGA}>
                        {Icon
                            ? <Icon className={classes.icon} />
                            : <img src={activeSrc} className={classes.img} alt={title} />

                        }
                        <Box className={classes.titleBox}>
                            <Typography variant={titleVariant} className={classes.title}>{title}</Typography>
                        </Box>
                    </Paper>
                </RouteLinkComponent>
            </Box>
        </div>
    )
}


export default React.memo(ServiceComponent);