import React, { useMemo, useEffect, useState } from 'react'
import Box from "@material-ui/core/Box"
import AppBarComponent from './AppBarComponent';
// import FooterComponent from './FooterComponent';
// import RenderLoaderComponent from './RenderLoaderComponent';
import { useSelector, useDispatch } from 'react-redux';
import { FETCH_MENU_REDUCER } from '../reducers/ContainerReducer';
// LANGUAGE
// import { MAIN_LANGUAGE, } from '../constants';
import { useHistory } from 'react-router-dom';
import routeGenerate from '../functions/routeGenerate';
import { CookiesProvider } from 'react-cookie';
import loadable from '@loadable/component'
import { ROUTES } from '../constants/routes';
// import FourthSectionComponent from './components/FourthSectionComponent';
const FooterComponent = loadable(() => import('./FooterComponent'))
const CookiesComponent = loadable(() => import('./CookiesComponent'))

const ContainerComponent = ({ hiddenScroll, h1Title, h1, stopDataLayer, disableHeader, disableLargeFooter, children, disableMargin }) => {
    const [offset, setOffset] = useState(0);
    // LANGUAGE
    // const lng = useMemo(() => MAIN_LANGUAGE, [])
    const history = useHistory();
    const { menu } = useSelector(state => state.ContainerReducer);
    const state = useSelector(state => state);
    const errorsArray = useMemo(() => Object.keys(state).filter((key) => state[key].error).map((key) => state[key].error), [state]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!stopDataLayer && window.dataLayer) {
            window.dataLayer.push({
                event: 'Pageview',
            });
        }
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!menu?.main_menu) {
            dispatch(FETCH_MENU_REDUCER());
        }
    }, [dispatch, menu])

    useEffect(() => {
        const appBar = document.getElementById('appBar');
        if (appBar) {
            setOffset(appBar.clientHeight);
        }
    }, [])
    useEffect(() => {
        if (errorsArray.length) {
            localStorage.setItem('lastError', errorsArray[0]?.status || 500)
            history.push(routeGenerate(ROUTES['empty']))
        }
    }, [errorsArray, history])
    useEffect(() => {
        const hash = history?.location?.hash
        if (!hiddenScroll) {
            if (hash) {
                const element = document.querySelector(hash);
                window.scrollTo(0, element?.offsetTop || 0)
            } else
                window.scrollTo(0, 0)
        }
        // eslint-disable-next-line
    }, [window.location.href])

    // LANGUAGE
    // useEffect(() => {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const lngParam = urlParams.get('lang');
    //     if (!lngParam) {
    //         history.push({ search: `?lang=${lng}` })
    //     }
    //     // eslint-disable-next-line
    // }, [history, window.location.href])
    return (
        <>
            {/* <RenderLoaderComponent /> */}
            {disableHeader
                ? ''
                : <AppBarComponent h1={h1} h1Title={h1Title} />
            }
            <Box component='main' minHeight={`calc(100vh - ${offset}px)`}>
                {children}
            </Box>
            {/* {disableBack
                ? ''
                :
                <Box mt='80px' mb='120px' >
                    <BackButtonComponent />
                </Box>
            } */}
            <FooterComponent disableMargin={disableMargin} disableLargeFooter={disableLargeFooter} />
            <CookiesProvider>
                <CookiesComponent />
            </CookiesProvider>
        </>
    )
}


export default React.memo(ContainerComponent);