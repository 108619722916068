import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LogoSvg from '../svg/LogoSvg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routeGenerate from '../functions/routeGenerate';
// import LangBoxComponent from './LangBoxComponent';

const useStyles = (mobileDisable, end) => makeStyles((theme) => ({
    logoContainer: {
        margin: end ? '12px auto' : '12px 5px 12px 5px',
        lineHeight: '0 !important',
        [theme.breakpoints.down('xs')]: {
            margin: '12px auto',
        }
    },
    logo: {
        height: 18,
        width: 120,
        cursor: 'pointer',
    },
    menuIcon: {
        width: 35,
        height: 35,
    },
    menuIconCont: {
        padding: '7px',
        [theme.breakpoints.down('sm')]: {
            display: mobileDisable ? 'none' : 'block',
        }
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        // [theme.breakpoints.up('md')]: {
            justifyContent: end ? 'flex-end' : 'flex-start',
        // },
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        }
    },
    link: {
        lineHeight: '0 !important',
    },
    classLang: {
        marginLeft: 50,
        fontSize: 28,
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 20,
        }
    },
    lang: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    langBox: {
        marginBottom: '0 !important',
    },
    langTitle: {
        fontSize: '28px !important',
        fontWeight: 'normal !important'
    },
}))

const LogoComponent = ({ Icon, mobileDisable, onClick = () => { }, end }) => {
    const classes = useStyles(mobileDisable, end)();
    const { t } = useTranslation();
    return (
        <Box className={classes.container} >
            {end ? "" : <IconButton edge="start" color="inherit" aria-label="menu" className={classes.menuIconCont} onClick={onClick}>
                <Icon color='primary' className={classes.menuIcon} />
            </IconButton>}
            {end
                ?
                ''
                    // <ListItem className={classes.lang} >
                    //     <LangBoxComponent classLang={classes.classLang} mobile classLangBox={classes.langBox} classLangTitle={classes.langTitle} />
                    // </ListItem>
                : <Box className={classes.logoContainer}>
                    <Link to={routeGenerate(t(`routes.main`))} className={classes.link}>
                        <LogoSvg className={classes.logo} />
                    </Link>
                </Box>}
            {end ? <IconButton edge="start" color="inherit" aria-label="menu" className={classes.menuIconCont} onClick={onClick}>
                <Icon color='primary' className={classes.menuIcon} />
            </IconButton> : ''}
        </Box>
    )
}


export default React.memo(LogoComponent);