import { GET } from "../functions/superagentSending";
import { API_MENU } from "../constants/api";

const initialState = {
    loading: false,
    menu: {},
    error: '',
    mobileSwipeShow: false,
};


const CHANGE_LOADING_SUCCESS = 'CHANGE_LOADING_SUCCESS'
const MOBILE_SWIPE_SHOW_SUCCESS = 'MOBILE_SWIPE_SHOW_SUCCESS'
const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS'
const FETCH_MENU_FAILURE = 'FETCH_MENU_FAILURE'


const fetchMenuFailure = error => ({
    type: FETCH_MENU_FAILURE,
    payload: error
})

const changeLoadingSuccess = (payload) => ({
    type: CHANGE_LOADING_SUCCESS,
    payload
})
const mobileSwipeShowSuccess = (payload) => ({
    type: MOBILE_SWIPE_SHOW_SUCCESS,
    payload
})
const getMenuSuccess = (payload) => ({
    type: GET_MENU_SUCCESS,
    payload
})


export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CHANGE_LOADING_SUCCESS:
            return {
                ...state,
                loading: payload 
            }
        case MOBILE_SWIPE_SHOW_SUCCESS:
            return {
                ...state,
                mobileSwipeShow: payload 
            }
        case GET_MENU_SUCCESS:
            return {
                ...state,
                loading: false, 
                menu: payload
            }
        case FETCH_MENU_FAILURE:
            return {
                ...state,
                loading: false, 
                error: payload
            }
        default:
            return state
    }
}

export const CHANGE_LOADING_REDUCER = (isShow) => {
    return function (dispatch) {
        dispatch(changeLoadingSuccess(isShow))
    }
}
export const MOBILE_SWIPE_SHOW_REDUCER = (isShow) => {
    return function (dispatch) {
        dispatch(mobileSwipeShowSuccess(isShow))
    }
}
export const GET_MENU_REDUCER = (menu) => {
    return function (dispatch) {
        dispatch(getMenuSuccess(menu))
    }
}
export const FETCH_MENU_REDUCER = () => {
    return function (dispatch) {
        dispatch(changeLoadingSuccess(true))
            GET(API_MENU)
                .then(res => {
                    dispatch(getMenuSuccess(res.body))
                    localStorage.setItem('menu', JSON.stringify(res.body));
                    localStorage.setItem('updateMenu', new Date().getTime())
                })
                .catch(error => {
                    dispatch(fetchMenuFailure(error))
                    dispatch(fetchMenuFailure(''))
                })
        }
}

