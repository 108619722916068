import TagManager from "react-gtm-module";

export const gaEventPush = (category, action) => {
	if (window.dataLayer)
		window.dataLayer.push({
			event: 'event',
			eventProps: {
				category,
				action
			}
		});
}


export const gtmInitialize = () => {
	const tagManagerArgs = {
		gtmId: process.env.REACT_APP_GTM
	}
	TagManager.initialize(tagManagerArgs);
}