export const API_MAIN = '/main';
export const API_RAMI_MAIN = '/wwwmainpage';
export const API_BLOG = '/blog';
export const API_NEWS = '/news';
export const API_PRODUCTS = '/products';
export const API_CASES = '/case';
export const API_MATERIALS = '/materials';
export const API_ABOUT = '/about';
export const API_CONTACT = '/contact';
export const API_INQUIRY = '/inquiry';
export const API_RECLAMATION = '/reclamation';
export const API_OFFICES = '/offices';
export const API_CUSTOM_PAGE = '/page';
export const API_MENU = '/menu';
export const API_SCHOOL = '/courses';
export const API_SEND_SCHOOL = '/course';
export const API_CAREER = '/career';
export const API_SEARCH = '/search';
export const API_EQUIPMENT = '/categories';
export const API_SERVICES = '/services';
export const API_USER_AVATAR = '/avatar';
export const API_CITY = '/officesbycity';
export const API_OFFICE_CONTACT = '/contact_office';
export const API_OFFICE_PHONE = '/landing_city_phone';
export const API_WEBSITE_MAP = '/page';
export const API_RULES = '/rental';
export const API_CUSTOM_MATERIALS_PAGE = '/materials_page';