import * as React from "react"

function LogoSvg(props) {
    return (
        <svg viewBox="0 0 142.91 21.05" {...props}>
            <g data-name="Layer 2">
                <path
                    d="M35.07 17.1h1.74v3.95h-5.53l-.89-3.95h-5.5l-.95 3.95H12.29l-3.45-6.71H6.5v6.69H0V17.1h1.89V4H0V0h8c5.87 0 8.71 2.37 8.71 7a6.74 6.74 0 01-3.47 6.13l2.24 4h4.81L23.68 4h-1.87V0h9.31zM11.92 7.23c0-2-1.24-3.28-4-3.28H6.5v6.57h1.39c2.79 0 4.03-1.29 4.03-3.29zm17.65 6.19L27.91 6h-.36l-1.79 7.45zM53.59 0h5.82v4h-1.9v13.1h1.9v3.95h-7.95V17.1h1.76V7.79l-4.29 6.39h-.36l-4.19-6.39v9.31h1.77v3.95h-7.61V17.1h1.63V4H38V0h6l4.8 7.52zm7.6 0h10.66v4h-3v13.1h3v3.95H61.19V17.1h3V4h-3zm28 17.1h2.21v3.95h-5.47l-3.45-6.71h-2.34v6.69h-6.5V17.1h1.89V4h-1.89V0h8c5.87 0 8.71 2.37 8.71 7a6.69 6.69 0 01-3.47 6.11zm-3.6-9.87c0-2-1.24-3.28-4-3.28h-1.45v6.57h1.39c2.79 0 4.03-1.29 4.03-3.29zM92.9 0h14v4h-7.2v4.47h5.47v4.11h-5.48v4.52h7.2v3.95H93.16V17.1H95V4h-2.1zm50 6.76h-3.26V4h-3.29v13.1h2.87v3.95h-10.36V17.1h2.87V4h-6v17.1h-3.47l-7.18-12.5v8.5h1.76v3.95h-8.13V17.1h2.21V4h-2.21V0h6l6.82 11.81V4h-1.69V0h23z"
                    fill="#003287"
                    data-name="Layer 1"
                />
            </g>
        </svg>
    )
}

export default LogoSvg
