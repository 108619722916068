export const ROUTES = {
	"main": "/",
	"about": "/o-spolocnosti",
	"equipment": "/technika",
	"services": "/sluzby",
	"blog": "/rami-pomocník",
	"career": "/kariera",
	"cases": "/realizacie",
	"contact": "/kontakt",
	"help": "/zakaznicka-podpora",
	"materials": "/materialy-na-stiahnutie",
	"news": "/novinky",
	"policy": "/stranka/privacy-policy",
	"page": "/stranka",
	"materials_page": "/strona-materialy",
	"rental_rules": "/pravidla-prenajmu",
	"map": "/mapa-stranky",
	"app_landing": "/aplikacjia-rami-online",
	"city_landing": "/mesto",
	"empty": "/chyba"
}

export const COOKIES_ROUTE = "stranka/cookies";