import React, { Suspense } from 'react';
import './App.css';
import Root from './Root';
import { Provider } from 'react-redux';
import store from './reducers';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import mainTheme from './MuiTheme';
import './translation/i18n';
import './setupProxy';
import { CookiesProvider } from 'react-cookie';
import RenderLoaderComponent from './components/RenderLoaderComponent';
// import ReactGA from 'react-ga';
require('dotenv').config();


function App() {



  return (
    <>
    {/* // <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}> */}
          {/* <link rel="canonical" href={window.location.origin} /> */}
        <CookiesProvider>
          <MuiThemeProvider theme={mainTheme}>
            <CssBaseline />
            <Suspense fallback={<RenderLoaderComponent />}>
                <Provider store={store}>
                  <Root />
                </Provider>
            </Suspense>
          </MuiThemeProvider>
        </CookiesProvider>
        </>
   
  );
}

export default App;
